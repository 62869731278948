/* website: 3986-bathursttoyota2
 * created at 2023-03-14 13:06 by Mfaye
 */

// Import all makes and organization styles files
@import "../templates/makes/toyota.scss";
@import "../utils/icons.scss";

.widget-sr{
  #button-login{
    .label{
      padding: 0 0 0 .5rem;
      font-size:12px;
    }
  }
  .icon-login{
    font-size: 12px !important;
    padding-right: 0 !important;
  }
  .widget-login{
    &>.widget-login-desktop{
      button.btn-register{
        margin-left: 5px;
      }
    }
  }
}
@include breakpoint(tablet){
  .widget-sr{
    #button-login{
      .label{
        padding: 0 .5rem;
        font-size:18px;
      }
    }
    .icon-login{
      font-size: 18px !important;
      padding-left: 8px !important;
    }
    .widget-login{
      &>.widget-login-desktop{
        button.btn-register{
          margin-left: 10px;
        }
      }
    }
  }
}